<template>
  <div>
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    <Table class="products-table event-modal-card-table">
      <template #header>
        <th :style="{ width: '5%' }">#</th>
        <th :style="{ width: '7%' }">{{ $t('commons.sku') }}</th>
        <th :style="{ width: '20%' }">{{ $t('commons.product') }}</th>
        <th :style="{ width: '7%' }">{{ $t('commons.quantity') }}</th>
        <th :style="{ width: '7%' }">{{ $t('commons.price') }}</th>
        <th :style="{ width: '5%' }">{{ $t('commons.discount') }}</th>
        <th :style="{ width: '7%' }">{{ $t('commons.total') }}</th>
        <th :style="{ width: '5%' }">{{ $t('commons.actions') }}</th>
        <th :style="{ width: '3%' }"></th>
      </template>
      <template #body>
        <tr v-if="tableData.length === 0" class="event-modal-card-table_row--empty">
          <td colspan="9">{{ $t('commons.noData') }}</td>
        </tr>

        <tr v-for="(orderProduct, index) in tableData" v-else :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ (orderProduct.product && orderProduct.product.sku) || '-' }}</td>
          <td>
            {{ (orderProduct.product && orderProduct.product.name) || $t('commons.generalProduct') }}
          </td>
          <td>
            {{ orderProduct.quantity }}
            <Tag v-if="orderProduct.diffs.some((diff) => diff.fieldPath.includes('quantity'))" type="warning">
              {{ $t('commons.differenceWithSupplier') }}
            </Tag>
          </td>
          <td>
            {{ orderProduct.price ? formatMoney(orderProduct.price) : $t('commons.unknown') }}
            <Tag v-if="orderProduct.diffs.some((diff) => diff.fieldPath.includes('price'))" type="warning">
              {{ $t('commons.differenceWithSupplier') }}
            </Tag>
          </td>
          <td>
            {{ formatPercent(orderProduct.discount) }}
            <Tag v-if="orderProduct.diffs.some((diff) => diff.fieldPath.includes('discount'))" type="warning">
              {{ $t('commons.differenceWithSupplier') }}
            </Tag>
          </td>
          <td>{{ orderProduct.totalAmount ? formatMoney(orderProduct.totalAmount) : $t('commons.unknown') }}</td>
          <td>
            <Button
              v-if="orderProduct.diffs.length"
              type="warning"
              class="difference-button"
              @click="$emit('solve-diff', index)"
            >
              {{ $t('commons.resolveDifference') }}
            </Button>
          </td>
          <td class="event-modal-card-table__kebab-icon">
            <el-dropdown
              v-if="orderProduct.product"
              trigger="click"
              placement="bottom"
              @command="(action) => handleAction(action, orderProduct, index)"
              @visible-change="(isVisible) => actionsVisibleChange(index, isVisible)"
            >
              <i class="co-kebab" :class="{ active: activeActions === index }" />
              <el-dropdown-menu>
                <el-dropdown-item :command="UPDATE_TYPE.QUANTITY">{{
                  $t('eventMapModal.updateProductModule.quantity')
                }}</el-dropdown-item>
                <el-dropdown-item :command="UPDATE_TYPE.PRICE">{{
                  $t('eventMapModal.updateProductModule.price')
                }}</el-dropdown-item>
                <el-dropdown-item :command="UPDATE_TYPE.DISCOUNT">{{
                  $t('eventMapModal.updateProductModule.discount')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { Tag, Tabs, Button } from '@/modules/core/components';
import Table from '@/modules/core/components/layouts/Table';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { percent } from '@/locale/numberConfig';

import { UPDATE_TYPE } from '../../../commons/components/UpdateProductModal.vue';

export default {
  name: 'ProductsTable',
  components: { Tabs, Table, Tag, Button },
  props: {
    creditProducts: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const activeTab = ref(0);
    const { formatCentsToCurrency } = useCurrency();

    const ordered = computed(() => props.creditProducts.filter((p) => p.quantity >= 0));
    const returned = computed(() => props.creditProducts.filter((p) => p.quantity < 0));
    const formatMoney = (value) => formatCentsToCurrency(value) ?? '-';
    const formatPercent = (value) =>
      typeof value === 'number' && !Number.isNaN(value)
        ? Number(value / 100).toLocaleString(root.$i18n.locale, percent)
        : '-';

    const activeActions = ref();
    const actionsVisibleChange = (index, isVisible) => (activeActions.value = isVisible ? index : null);

    const handleAction = (action, orderProduct, index) => {
      if (Object.values(UPDATE_TYPE).indexOf(action) !== -1) {
        let value = '-';
        switch (action) {
          case UPDATE_TYPE.DISCOUNT:
            value = formatPercent(orderProduct[action]);
            break;
          case UPDATE_TYPE.PRICE:
            value = formatMoney(orderProduct[action]);
            break;
          case UPDATE_TYPE.QUANTITY:
            value = orderProduct[action].toLocaleString(root.$i18n.locale) ?? value;
            break;
          default:
        }
        emit('update', {
          index,
          product: orderProduct.product,
          value,
          updateType: action,
        });
      }
    };

    return {
      activeTab,
      tableData: computed(() => {
        switch (activeTab.value) {
          case 0:
            return ordered.value;
          case 1:
            return returned.value;
          default:
            return [];
        }
      }),
      tabs: [
        {
          text: root.$i18n.t('eventMapModal.billingCard.productTable.tabs.details'),
          badgeValue: ordered.value.length,
        },
      ],
      formatPercent,
      formatMoney,
      activeActions,
      actionsVisibleChange,
      handleAction,
      UPDATE_TYPE,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style';

.products-table {
  tr {
    .difference-button {
      visibility: hidden;
    }

    &:hover .difference-button {
      visibility: visible;
    }
  }
}
</style>

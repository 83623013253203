<template>
  <el-dialog
    top="12vh"
    width="640"
    visible
    append-to-body
    :show-close="false"
    :before-close="close"
    custom-class="rounded match-invoice-modal"
  >
    <template #title>
      <div class="p-4 d-flex justify-content-between align-items-top">
        <div>
          <h2 class="mb-2">{{ translate('title') }}</h2>
          <p>
            {{
              `${billing.supplier.name} &#183; ${formatDate(billing.date)} &#183; ${$t(
                `document.exports.schema.type.shortName.${billing.eventReferences[0].document.type}`
              )}  ${billing.eventReferences[0].document.documentNumber} &#183; ${formatMoney(billing.netAmount)}`
            }}
          </p>
        </div>
        <Button type="icon" class="p-0" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="p-4 h-100">
      <h4 class="mb-4">{{ translate('markOrders') }}</h4>
      <div style="max-height: calc(100% - 33px)" class="overflow-scroll">
        <Table
          v-loading="loading"
          :data="tableData"
          :columns="columns"
          :row-selection.sync="selectedRowIndexes"
          row-click-selection-toggle
          class="mb-4 border rounded"
          custom-class="match-invoice-table"
        >
          <template #cell-orderDate="{ rowData: { orderDate } }">
            <p>{{ orderDate ? formatDate(orderDate) : '-' }}</p>
          </template>
          <template #cell-deliveryDate="{ rowData: { deliveryDate } }">
            <p>{{ deliveryDate ? formatDate(deliveryDate) : '-' }}</p>
          </template>
          <template #cell-total="{ rowData: { total } }">
            <p>{{ formatMoney(total) }}</p>
          </template>
          <template #cell-status="{ rowData: { status } }">
            <Tag v-if="status">
              <template v-if="status === 'linked'">
                {{ translate('statuses.linked') }}
              </template>
              <template v-else-if="status === 'unlink'">
                {{ translate('statuses.unlink') }}
              </template>
              <template v-else-if="status === 'link'">
                {{ translate('statuses.link') }}
              </template>
            </Tag>
          </template>
        </Table>
      </div>
    </div>
    <template #footer>
      <div class="p-4">
        <div class="d-flex flex-reverse justify-content-between align-items-center mb-4">
          <span class="text-end">
            <p class="text-typography-primary">{{ translate('totalAmountLeftToMatch') }}</p>
            <h2 class="text-typography-primary">
              {{ formatMoney(totalAmountMatched) }}
            </h2>
          </span>
          <el-pagination
            v-if="totalEvents > pageLimit"
            class="px-0"
            small
            layout="prev, pager, next, jumper"
            background
            :current-page.sync="currentPage"
            :page-size="pageLimit"
            :page-count="Math.ceil(totalEvents / pageLimit)"
          />
        </div>
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button :disabled="!isDirty" @click="handleSaveClick">{{ $t('commons.save') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { reject, isNil } from 'ramda';

import { Button, Table, Tag } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useUnbilledOrders } from '@/modules/billing';

import { useDeliveries } from '../../../compositions/billing';

const TABLE_HEADERS = {
  ORDER_DATE: 'orderDate',
  DELIVERY_DATE: 'deliveryDate',
  DOCUMENT_NUMBER: 'documentNumber',
  TOTAL: 'total',
  STATUS: 'status',
};

export default {
  components: {
    Table,
    Button,
    Tag,
    CloseIcon,
  },
  props: {
    billing: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const businessId = computed(() => props.billing.businessId);
    const { formatCentsToCurrency } = useCurrency();

    const { unbilledOrders, loading: loadingUnbilledOrders } = useUnbilledOrders(() => ({
      businessId: businessId.value,
      supplierId: props.billing.supplier.id,
    }));

    const mappedBilledOrders = computed(() => {
      return props.billing.orderLinks
        .map(({ order, amount, externalRef }) => {
          const netAmount = amount ? amount : order?.netAmount ? Math.round(order.netAmount * 100) : null;
          return {
            id: order?.id ?? null,
            date: order?.date ?? null,
            netAmount,
            externalRef: externalRef,
          };
        })
        .sort((a, b) => a.date - b.date);
    });
    const mappedUnbilledOrders = computed(() => {
      return unbilledOrders.value
        .map((unbilled) => ({
          id: unbilled.orderId,
          date: unbilled.date,
          netAmount: unbilled.netAmount ? Math.round(unbilled.netAmount * 100) : unbilled.netAmount,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    });
    const allEvents = computed(() => [...mappedBilledOrders.value, ...mappedUnbilledOrders.value]);

    const orderIds = computed(() => {
      if (loadingUnbilledOrders.value) return [];
      return allEvents.value.filter(({ id }) => id).map(({ id }) => id);
    });

    const pageLimit = 20;
    const currentPage = ref(1);

    const { deliveries, loading: deliveriesLoading } = useDeliveries(
      computed(() => ({ businessId: businessId.value, orderIds: orderIds.value }))
    );

    const deliveryByOrderId = computed(() => {
      return deliveries.value.reduce((ordersMap, delivery) => {
        delivery.orderIds.forEach((orderId) => {
          ordersMap[orderId] = delivery;
        });
        return ordersMap;
      }, {});
    });

    const loading = computed(() => loadingUnbilledOrders.value || deliveriesLoading.value);
    const paginatedEvents = computed(() => {
      if (loading.value) return [];
      return allEvents.value.slice((currentPage.value - 1) * pageLimit, currentPage.value * pageLimit).map((order) => ({
        ...order,
        delivery: deliveryByOrderId.value[order.id],
      }));
    });

    const selectedEventIds = ref(props.billing.orderLinks.filter(({ order }) => order).map(({ order }) => order.id));
    const selectedRowIndexes = computed({
      get: () =>
        paginatedEvents.value
          .filter((event) => selectedEventIds.value.includes(event.id) || event.id === null)
          .map((event) => paginatedEvents.value.indexOf(event)),
      set: (indexes) => {
        selectedEventIds.value = selectedEventIds.value.filter((id) =>
          paginatedEvents.value.every((event) => event.id !== id)
        );
        const newSelectedEventIds = paginatedEvents.value
          .filter(({ id }, idx) => indexes.includes(idx) && id)
          .map(({ id }) => id);
        selectedEventIds.value = [...selectedEventIds.value, ...newSelectedEventIds];
      },
    });

    return {
      pageLimit,
      currentPage,
      loading,
      selectedRowIndexes,
      selectedEventIds,
      mappedBilledOrders,
      paginatedEvents,
      allEventsById: computed(() =>
        allEvents.value.reduce((eventMap, event) => {
          if (event.id) eventMap[event.id] = event;
          return eventMap;
        }, {})
      ),
      deliveryByOrderId,
      totalEvents: computed(() => allEvents.value.length),
      formatMoney: (number) => formatCentsToCurrency(number) ?? root.$i18n.t('commons.unknown'),
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.short),
    };
  },
  computed: {
    totalAmountMatched() {
      return (
        this.billing.netAmount -
        this.selectedEventIds.reduce((totalNetAmount, id) => totalNetAmount + this.allEventsById[id].netAmount, 0)
      );
    },
    tableData() {
      return this.paginatedEvents.map((event) => {
        const document =
          event.delivery?.eventReferences.length > 1
            ? event.delivery.eventReferences.find((ref) => ref.document.documentNumber === event.externalRef)?.document
            : event.delivery?.eventReferences[0].document;
        const documentSourceType = document?.type;
        const documentNumber = document?.documentNumber;
        const documentTypeAndNumber = documentSourceType
          ? `${this.$t(`document.exports.schema.type.shortName.${documentSourceType}`)} ${this.$t(
              'document.exports.schema.fields.documentNumber'
            )} ${documentNumber}`
          : null;

        return {
          id: event.id,
          [TABLE_HEADERS.ORDER_DATE]: event.date,
          [TABLE_HEADERS.DELIVERY_DATE]: event.delivery?.date,
          [TABLE_HEADERS.DOCUMENT_NUMBER]: documentTypeAndNumber ?? event.externalRef,
          [TABLE_HEADERS.TOTAL]: event.netAmount,
          [TABLE_HEADERS.STATUS]: event.id ? this.getStatus(event.id) : 'linked',
          selectionDisabled: !event.id || !event.netAmount,
        };
      });
    },
    columns() {
      return [
        {
          header: this.translate('orderDate'),
          key: TABLE_HEADERS.ORDER_DATE,
          width: '120px',
        },
        {
          header: this.translate('deliveryDate'),
          key: TABLE_HEADERS.DELIVERY_DATE,
          width: '120px',
        },
        {
          header: this.translate('documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
          width: '160px',
        },
        {
          header: this.translate('totalAmount'),
          key: TABLE_HEADERS.TOTAL,
          width: '125px',
        },
        {
          header: this.translate('status'),
          key: TABLE_HEADERS.STATUS,
          width: '110px',
        },
      ];
    },
    isDirty() {
      const billedIds = this.mappedBilledOrders.map(({ id }) => id);
      return !(
        billedIds.every((id) => this.selectedEventIds.includes(id)) && billedIds.length === this.selectedEventIds.length
      );
    },
  },
  methods: {
    handleSaveClick() {
      const orderLinks = [];
      const deliveryRefs = [];
      this.billing.deliveryRefs.forEach((deliveryRef) => {
        if (!deliveryRef.delivery)
          deliveryRefs.push(
            reject(isNil)({
              externalRef: deliveryRef.externalRef,
              date: deliveryRef.date,
            })
          );
      });
      this.billing.orderLinks.forEach((orderLink) => {
        if (!orderLink.order) orderLinks.push({ externalRef: orderLink.externalRef });
      });
      this.selectedEventIds.forEach((eventId) => {
        const order = this.allEventsById[eventId];
        orderLinks.push(
          reject(isNil)({
            orderId: order.id,
            externalRef: order.externalRef,
          })
        );

        const delivery = this.deliveryByOrderId[order.id];
        if (delivery)
          deliveryRefs.push(
            reject(isNil)({
              deliveryId: delivery.id,
              externalRef: delivery.externalRef,
              date: delivery.date,
            })
          );
      });
      this.$emit('update', { orderLinks, deliveryRefs });
    },
    close() {
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`eventMapModal.billingCard.matchInvoiceModal.${key}`);
    },
    getStatus(eventId) {
      const isSelected = this.selectedEventIds.includes(eventId);
      const isPartOfLinks = this.mappedBilledOrders.some((e) => e.id === eventId);
      if (isSelected && isPartOfLinks) return 'linked';
      if (isSelected && !isPartOfLinks) return 'link';
      if (!isSelected && isPartOfLinks) return 'unlink';
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .match-invoice-modal {
  $modal-height: 80vh;
  height: $modal-height;

  $header-height: 90px;
  $footer-height: 130px;
  $body-height: calc($modal-height - $header-height - $footer-height);

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }

  .el-dialog__header {
    height: $header-height;
  }
  .el-dialog__body {
    height: $body-height;
  }

  .el-dialog__footer {
    height: $footer-height;
  }
}
.informative-color {
  background-color: change-color($color: $informative, $lightness: 98.1%);
}

.match-invoice-table {
  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }
  }
}
</style>
